import { AudioProcessor } from "@twilio/voice-sdk";

export class AudioBoostProcessor implements AudioProcessor {
  public audioContext: AudioContext | null;
  private background: MediaElementAudioSourceNode;
  private destination: MediaStreamAudioDestinationNode;
  public gainLevel: number = 0;

  constructor(gainLevel: number) {
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
    }
    this.gainLevel = gainLevel;
  }

  async createProcessedStream(stream: MediaStream): Promise<MediaStream> {
    const source = this.audioContext.createMediaStreamSource(stream);
    const gain = this.audioContext.createGain();
    gain.gain.value = this.gainLevel;
    console.log(
      `%c[Gain Control Initialized ➞%c(${this.gainLevel})%c]`,
      "background:#000;color:lime;padding:5px;",
      "color:#fff;background:#000;padding:5px 0px",
      "background:#000;color:red;padding:5px 0px;",
    );
    this.destination = this.audioContext.createMediaStreamDestination();
    source.connect(gain).connect(this.destination);
    return this.destination.stream;
  }

  async destroyProcessedStream(stream: MediaStream): Promise<void> {
    this.background?.disconnect();
    this.destination?.disconnect();
  }
}
