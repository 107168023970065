import { client } from "@/config";
import { AudioBoostProcessor } from "@/contexts/AudioBoostProcessor";
import { createCallConfiguration } from "@/data/pg/bulkInserts";
import { db } from "@/db/db";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import { callConfiguration } from "@/db/schema";
import { Call, Device } from "@twilio/voice-sdk";
import cuid from "cuid";
import { and, asc, eq, not } from "drizzle-orm";
import log from "loglevel";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { CallConfiguration, CallStatus } from "web-client/api/data-contracts";

export type CallConfigurationRecord = typeof callConfiguration.$inferSelect;
export type CallConfigurationState = typeof callConfiguration.$inferSelect.state;

// Define the shape of our context
interface CallContextType {
  currentCall: Call | null;
  callTextStatus: string;
  callStartTime: number | null;
  createCallAndListen: (params: { fromAccountId: string; toAccountId: string; feedId: string }) => Promise<void>;
  listenForCalls: (callConfiguration: CallConfiguration) => Promise<void>;
  disconnectCall: (state?: CallConfigurationState, duration?: number) => void;
}

// Create the context with a default value
const CallContext = createContext<CallContextType | undefined>(undefined);

let currentCallConfiguration: CallConfigurationRecord | null = null;
let callStartTime: number | null = null;

function callStatusFromState(state: CallConfigurationState): CallStatus {
  switch (state) {
    case "accepted":
      return "inProgress";
    case "rejected":
      return "noAnswer";
    case "disconnected":
      return "completed";
    case "canceled":
      return "canceled";
    case "errored":
      return "failed";
    case "ringing":
      return "ringing";
    case "queued":
      return "queued";
    case "reconnecting":
      return "unknown";
    case "reconnected":
      return "unknown";
    case "timedout":
      return "timedout";
  }
}

const setCurrentCallConfigurationState = (state: CallConfigurationState, duration?: number) => {
  if (currentCallConfiguration) {
    db.update(callConfiguration).set({ state }).where(eq(callConfiguration.id, currentCallConfiguration.id)).execute();
    if (!currentCallConfiguration.initiate) {
      const durationSeconds = callStartTime ? Math.floor((Date.now() - callStartTime) / 1000) : null;
      const callStatus = callStatusFromState(state);
      console.log({ callStatus });
      switch (callStatus) {
        case "noAnswer":
          console.log({ duration });
          client.createCallEvent(currentCallConfiguration.id, { callStatus, durationSeconds: duration ?? 0 });
          break;
        case "completed":
        case "failed":
        case "canceled":
          client.createCallEvent(currentCallConfiguration.id, { callStatus, durationSeconds });
          break;
        case "inProgress":
        case "ringing":
          client.createCallEvent(currentCallConfiguration.id, { callStatus });
          break;
        default:
          log.warn("unhandled call status", callStatus);
      }
    }
  }
};

// Provider component
export function CallProvider({ children }: { children: ReactNode }) {
  const [currentCall, setCurrentCall] = useState<Call | null>(null);
  const [callTextStatus, setCallTextStatus] = useState<string>("");
  const [device, setDevice] = useState<Device | null>(null);
  const params = new URLSearchParams(document.location.search);
  const gainControl = Number.parseFloat(params.get("gainControl"));
  if (gainControl !== null && !Number.isNaN(gainControl)) {
    console.log(
      `%c[Gain Control Set:%c(${gainControl})%c]`,
      "background:#000;color:red;padding:5px;",
      "color:#fff;background:#000;padding:5px 0px",
      "background:#000;color:red;padding:5px 0px;",
    );
  }

  const { rows: callConfigurations } = useDrizzleSelect(
    db
      .select()
      .from(callConfiguration)
      .where(eq(callConfiguration.state, "queued"))
      .orderBy(asc(callConfiguration.createdAt)),
  );

  const resetData = useCallback(() => {
    setCurrentCall(null);
    setDevice(null);
    setCallTextStatus(() => "");
    currentCallConfiguration = null;
    callStartTime = null;
  }, []);

  // Handle queued call configurations from appsync, which makes an outbound call
  useEffect(() => {
    const f = async () => {
      if (callConfigurations.length === 0) {
        return;
      }
      log.info("[CALL] Found queued call configurations", callConfigurations);
      if (currentCall) {
        log.info("[CALL] In active call. Rejecting queued call configurations", callConfigurations);
        await db
          .update(callConfiguration)
          .set({ state: "rejected" })
          .where(eq(callConfiguration.state, "queued"))
          .execute();
        return;
      }
      currentCallConfiguration = callConfigurations[0];
      log.info("[CALL] No active call. Accepting queued call configurations", callConfigurations);
      await db
        .update(callConfiguration)
        .set({ state: "ringing" })
        .where(eq(callConfiguration.id, currentCallConfiguration.id))
        .execute();

      if (callConfigurations.length > 1) {
        await db
          .update(callConfiguration)
          .set({ state: "rejected" })
          .where(and(eq(callConfiguration.state, "queued"), not(eq(callConfiguration.id, currentCallConfiguration.id))))
          .execute();
      }

      if (currentCallConfiguration.initiate) {
        await initializeCall();
      } else {
        await listenForCalls();
      }
    };
    f();
  }, [callConfigurations]);

  // Handle call acceptance
  const handleCallAccept = useCallback((...args: any[]) => {
    console.log("[CALL] handleCallAccept", args);
    callStartTime = Date.now();
    setCurrentCallConfigurationState("accepted");
    setCallTextStatus(() => "accepted");
  }, []);

  // Handle call disconnection
  const handleCallDisconnect = useCallback((...args: any[]) => {
    console.log("[CALL] handleCallDisconnect", args);
    setCurrentCallConfigurationState("disconnected");
    setCallTextStatus(() => "disconnected");
    resetData();
  }, []);

  // Handle call cancellation
  const handleCallCancel = useCallback((...args: any[]) => {
    console.log("[CALL] handleCallCancel", args);
    setCurrentCallConfigurationState("canceled");
    setCallTextStatus(() => "canceled");
    resetData();
  }, []);

  const handleCallError = useCallback((...args: any[]) => {
    console.log("[CALL] handleCallError", args);
    setCurrentCallConfigurationState("errored");
    setCallTextStatus(() => "errored");
    resetData();
  }, []);

  const handleCallRejected = useCallback((...args: any[]) => {
    console.log("[CALL] handleCallRejected", args);
    setCurrentCallConfigurationState("rejected");
    setCallTextStatus(() => "rejected");
    resetData();
  }, []);

  const handleDeviceError = useCallback((...args: any[]) => {
    console.log("[CALL] handleDeviceError", args);
    setCurrentCallConfigurationState("errored");
    setCallTextStatus(() => "errored");
    resetData();
  }, []);

  const handleDeviceRegistered = useCallback((...args: any[]) => {
    console.log("[CALL] handleDeviceRegistered", args);
    setCurrentCallConfigurationState("ringing");
    setCallTextStatus(() => "ringing");
  }, []);

  const handleDeviceDisconnected = useCallback(
    (...args: any[]) => {
      console.log("[CALL] handleDeviceDisconnected", args);
      setCurrentCallConfigurationState("disconnected");
      setCallTextStatus(() => "disconnected");
      resetData();
    },
    [setCurrentCallConfigurationState],
  );

  const registerCallListeners = useCallback(
    (call: Call) => {
      call.on("accept", handleCallAccept);
      call.on("cancel", handleCallCancel);
      call.on("disconnect", handleCallDisconnect);
      call.on("reject", handleCallRejected);
      call.on("error", handleCallError);
    },
    [handleCallAccept, handleCallCancel, handleCallDisconnect, handleCallError, handleCallRejected],
  );

  const registerDeviceListeners = useCallback(
    (device: Device) => {
      device.on("error", handleDeviceError);
      device.on("registered", handleDeviceRegistered);
      device.on("disconnected", handleDeviceDisconnected);
    },
    [handleDeviceError, handleDeviceRegistered, handleDeviceDisconnected],
  );

  // Handle incoming call
  const handleIncomingCall = useCallback(
    (call: Call) => {
      console.log("[CALL] handleIncomingCall", call);
      setCurrentCall(call);
      registerCallListeners(call);
      call.accept();
    },
    [registerCallListeners],
  );

  // Initialize a call
  const initializeCall = useCallback(async () => {
    const { fromAlias, fromAccountId, fromToken, toAlias, toAccountId, toToken } = currentCallConfiguration;

    // Create a new device instance
    const newDevice = new Device(fromToken, {
      enableImprovedSignalingErrorPrecision: true,
      codecPreferences: ["opus", "pcmu"] as any,
    });

    registerDeviceListeners(newDevice);

    setDevice(newDevice);
    await newDevice.register();

    const call = await newDevice.connect({
      params: {
        To: toAlias,
      },
    });

    setCurrentCall(call);
    registerCallListeners(call);
  }, [registerDeviceListeners, registerCallListeners]);

  const createCallAndListen = useCallback(
    async ({ fromAccountId, toAccountId, feedId }: { fromAccountId: string; toAccountId: string; feedId: string }) => {
      console.log("[CALL] createCallAndListen", { fromAccountId, toAccountId });
      const callId = cuid();
      const callConfiguration = await client.createCall({
        callId,
        fromAccountId,
        toAccountId,
        feedId,
      });

      if (!callConfiguration) {
        console.error("[CALL] call could not be created");
        resetData();
        return Promise.reject("[CALL] call could not be created");
      }

      const callConfigurationRecords = await createCallConfiguration(callConfiguration, "ringing");
      currentCallConfiguration = callConfigurationRecords[0];
      await listenForCalls();

      await client.createCallEvent(callId, { callStatus: "ringing" });
    },
    [],
  );

  const listenForCalls = useCallback(async () => {
    const { toToken } = currentCallConfiguration;
    console.log("[CALL] Listening for calls", currentCallConfiguration);

    // Create a new device instance
    const newDevice = new Device(toToken, {
      enableImprovedSignalingErrorPrecision: true,
      codecPreferences: ["opus", "pcmu"] as any,
    });

    await newDevice.audio.setAudioConstraints({
      autoGainControl: false,
      noiseSuppression: false,
      echoCancellation: false,
    });

    // Increase the gain via Audio Processor
    // increaseAudioGain: number is a flag in launch darkly
    if (gainControl) {
      const processor = new AudioBoostProcessor(gainControl);
      await newDevice.audio.addProcessor(processor);
    }

    newDevice.on("incoming", handleIncomingCall);
    newDevice.on("error", handleDeviceError);
    newDevice.on("registered", handleDeviceRegistered);
    newDevice.on("disconnected", handleDeviceDisconnected);

    setDevice(newDevice);
    await newDevice.register();
  }, [handleIncomingCall]);

  const disconnectCall = useCallback(
    async (state?: CallConfigurationState, duration?: number) => {
      if (currentCall) {
        console.log("[CALL] disconnecting call");
        currentCall.disconnect();
      }
      if (state) {
        setCurrentCallConfigurationState(state, duration);
      }
      device?.disconnectAll();
      await device?.unregister();
      device?.destroy();
      resetData();
    },
    [currentCall, device],
  );

  useEffect(() => {
    return () => {
      if (device) {
        console.log("[CALL] cleaning up device on unmount");
        device?.destroy();
      }
    };
  }, [device]);

  const value = {
    callTextStatus,
    currentCall,
    createCallAndListen,
    listenForCalls,
    disconnectCall,
    callStartTime,
  };

  return <CallContext.Provider value={value}>{children}</CallContext.Provider>;
}

// Custom hook to use the call context
export function useCall() {
  const context = useContext(CallContext);
  if (context === undefined) {
    throw new Error("useCall must be used within a CallProvider");
  }
  return context;
}
