import { AccountEvent } from "@/db/types";

export const StartedEvent = "Started Listening to Feed Item";
export const CompletedEvent = "Finished Listening to Feed Item";
export const SeenEvent = "Saw Feed Item";
export const DeviceMutedEvent = "Device volume was muted when message arrived";
export const PlayedUnknownEvent = "Message played but driver may not be present";
export const SentEvent = "Sent";
export const DriverSawMessageEvent = "Message seen by driver";
export const DriverStartedListeningEvent = "Driver started listening to message";
export const DriverHeardMessageEvent = "Message heard by driver";

export const PausedEvent = "Paused Feed Item";

// The order of this array controls what order the receipts are displayed
export const EventTypes = [
  StartedEvent,
  CompletedEvent,
  DriverHeardMessageEvent,
  SeenEvent,
  DriverSawMessageEvent,
  DeviceMutedEvent,
  PlayedUnknownEvent,
  SentEvent,
  PausedEvent,
];

// convert array into types
export type EventType = (typeof EventTypes)[number];

export interface AccountEventWithExtra extends AccountEvent {
  event: string;
  driverEvent: boolean;
  username: string;
  eventType: EventType;
}

export type GroupedItemEvent = {
  key: EventType;
  events: AccountEventWithExtra[];
};

export type GroupsOfEvents = GroupedItemEvent[];
