import AvatarWithStatus from "@/components/AvatarWithStatus";
import SimpleModal from "@/components/SimpleModal";
import { AccountInfo } from "@/models/accountInfo";
import {
  CompletedEvent,
  DeviceMutedEvent,
  DriverHeardMessageEvent,
  DriverSawMessageEvent,
  PlayedUnknownEvent,
  SeenEvent,
  StartedEvent,
} from "@/transformers/transformedEventTypes";
import Papa from "papaparse";

import { type EventType, type GroupsOfEvents } from "@/transformers/transformedEventTypes";

import { getFormattedTime } from "@/components/Search/search";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { Done, DoneAll, FileDownloadOutlined, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import HearingIcon from "@mui/icons-material/Hearing";
import HearingDisabledIcon from "@mui/icons-material/HearingDisabled";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { Box, Collapse, ListItem, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

export default function NewFeedItemStatus({
  sender,
  allDriverEvents,
  count,
  groups,
  createdAt,
}: { sender: AccountInfo; allDriverEvents: boolean; count: number; groups: GroupsOfEvents; createdAt: string }) {
  const theme = useTheme();
  const { ampli } = React.useContext(TrackingContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const fetchIconDetails = (key: EventType, size: number, color?: boolean) => {
    const iconList = [
      {
        key: DeviceMutedEvent,
        color: theme.palette.error.main,
        icon: allDriverEvents ? (
          <VolumeOffIcon sx={{ fontSize: `${size}px` }} />
        ) : (
          <DoneAll sx={{ fontSize: `${size}px` }} />
        ),
      },
      {
        key: DriverSawMessageEvent,
        color: theme.palette.brand.other.green.light,
        icon: allDriverEvents ? (
          <MarkChatReadIcon sx={{ fontSize: `${size}px` }} />
        ) : (
          <DoneAll sx={{ fontSize: `${size}px` }} />
        ),
      },
      {
        key: DriverHeardMessageEvent,
        color: theme.palette.brand.other.green.light,
        icon: <HearingIcon sx={{ fontSize: `${size}px` }} />,
      },
      {
        key: PlayedUnknownEvent,
        color: theme.palette.warning.main,
        icon: <HearingDisabledIcon sx={{ fontSize: `${size}px` }} />,
      },
      { key: CompletedEvent, color: "magenta", icon: <DoneAll sx={{ fontSize: `${size}px` }} /> },
      {
        key: SeenEvent,
        color: theme.palette.brand.other.green.light,
        icon: <DoneAll sx={{ fontSize: `${size}px` }} />,
      },
      {
        key: StartedEvent,
        color: theme.palette.brand.other.green.light,
        icon: <DoneAll sx={{ fontSize: `${size}px` }} />,
      },
    ];
    const found = iconList?.find((e) => e.key === key);
    if (!found) {
      return <></>;
    }
    return (
      <Box
        sx={{
          p: "2px",
          borderRadius: 1,
          background: color ? found?.color : "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {found?.icon}
      </Box>
    );
  };

  const [activeGroups, setActiveGroups] = useState<string[]>([]);
  useEffect(() => {
    const groupData = groups?.map((group) => group.key)?.filter((item) => item);
    groupData.push("Sent");
    setActiveGroups(() => groupData);
  }, [groups?.length]);

  const handleCSVDownload = () => {
    const csvData = [];

    const firstEvent = groups?.find((group) => group?.events?.length > 0)?.events[0];

    const fileName = `${firstEvent.itemId}-${firstEvent.feedId}-message-details-${new Date().toISOString()}.csv`;

    groups.forEach((group) => {
      group.events.forEach((event) => {
        const usernameIndex = csvData.findIndex((e) => e.username === event.username);
        if (usernameIndex === -1) {
          csvData.push({ username: event.username, [event.eventType]: getFormattedTime(event.createdAt) });
        } else {
          csvData[usernameIndex][event.eventType] = getFormattedTime(event.createdAt);
        }
      });
    });

    const csvUnparsed = Papa.unparse(csvData);

    if (csvUnparsed) {
      const blob = new Blob([csvUnparsed], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document?.createElement("a");
      if (url) {
        a.href = url;
        a.download = fileName;
        a?.click();
        URL.revokeObjectURL(url);
      }
    }
  };

  const StatusListItem = ({
    accountId,
    username,
    date,
  }: {
    accountId: string;
    username: string;
    date: string;
  }) => {
    return (
      <ListItem
        className="status-list-item"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 2,
          background: theme.palette.neutral.dark,
          borderBottom: `1px solid ${theme.palette.secondary.main}`,
        }}
      >
        <Box sx={{ display: "flex", pl: 1, alignItems: "center", gap: 1 }}>
          <AvatarWithStatus accountId={accountId} key={`avatar-${accountId}`} />
          <Typography fontWeight={500}>{username}</Typography>
        </Box>
        <Box>{getFormattedTime(date)}</Box>
      </ListItem>
    );
  };

  const toggleActiveGroup = useCallback(
    (key: string) => {
      if (activeGroups.includes(key)) {
        setActiveGroups(activeGroups.filter((group) => group !== key));
      } else {
        setActiveGroups([...activeGroups, key]);
      }
    },
    [activeGroups],
  );

  const StatusListHeaderContainer = ({
    icon,
    text,
    status,
  }: { icon: React.ReactNode; text: string; status: boolean }) => {
    return (
      <Box
        sx={{
          p: 2,
          background: "#111",
          width: "100%",
          display: "flex",
          gap: 2,
          alignItems: "center",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => toggleActiveGroup(text)}
      >
        <Box sx={{ display: "flex" }}>{icon}</Box>
        <Box sx={{ flexGrow: 1, textAlign: "left" }}>{text}</Box>
        <Box>{status ? <KeyboardArrowUp role="img" /> : <KeyboardArrowDown role="img" />}</Box>
      </Box>
    );
  };

  if (count === 0) {
    return <></>;
  }

  return (
    <>
      <SimpleModal
        title={
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Typography variant="h6" component="h2">
              Message Details
            </Typography>
            <Box
              component="button"
              className="basic-button"
              sx={{
                gap: 1,
                px: 1,
                py: 0,
                height: "25px",
                borderRadius: 1,
                fontSize: "0.875rem",
                alignItems: "center",
                display: "flex",
                border: `1px solid ${theme.palette.info.light}`,
              }}
              onClick={handleCSVDownload}
            >
              <Box sx={{ display: "flex" }}>
                <FileDownloadOutlined role="presentation" sx={{ width: 18, height: 18 }} />
              </Box>
              <Box>CSV</Box>
            </Box>
          </Box>
        }
        closeModal={() => setModalOpen(() => false)}
        open={modalOpen}
      >
        <Box>
          <StatusListHeaderContainer
            status={activeGroups?.includes("Sent")}
            icon={<Done sx={{ fontSize: "20px" }} />}
            text="Sent"
          />
          <Collapse in={activeGroups?.includes("Sent")}>
            <StatusListItem username={sender.name} accountId={sender.id} date={createdAt} />
          </Collapse>

          {groups?.map((item, itemIndex) => (
            <Box sx={{}} key={`${itemIndex}-${item.key}`}>
              <StatusListHeaderContainer
                status={activeGroups?.includes(item.key)}
                icon={fetchIconDetails(item.key, 20, false)}
                text={item.key}
              />
              <Collapse in={activeGroups?.includes(item.key)}>
                <Box>
                  {item?.events?.map((event) => (
                    <StatusListItem
                      accountId={event.accountId}
                      username={event.username}
                      date={event.createdAt}
                      key={`event-${event.id}`}
                    />
                  ))}
                </Box>
              </Collapse>
            </Box>
          ))}
        </Box>
      </SimpleModal>
      <Box
        onClick={() => {
          ampli.viewMessageDetails();
          setModalOpen(() => true);
        }}
      >
        {allDriverEvents ? (
          <Box sx={{ fontSize: "10px", display: "block" }}>
            {groups?.map((item) => (
              <Box key={`all-driver-events-${item.key}`} sx={{ display: "inline-block", mr: 0.5, mb: 0.5 }}>
                <Box
                  aria-label="View message details"
                  component="button"
                  className="basic-button"
                  sx={{ display: "flex", gap: 0.5 }}
                >
                  <Box>{fetchIconDetails(item.key, 17, true)}</Box>
                  <Box sx={{ fontSize: "13px" }}>{item.key}</Box>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box component="button" className="basic-button" sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
            <Box sx={{ display: "flex" }}>
              <DoneAll fontSize="small" />
            </Box>
            <Box>{count}</Box>
          </Box>
        )}
      </Box>
    </>
  );
}
