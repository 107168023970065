import { format } from "date-fns";
import { SearchFeedsResult, SearchMessageResult } from "../../../web-client/api/data-contracts";

export interface PaginatedSearchData {
  results: SearchMessageResult[] | SearchFeedsResult[] | [];
  meta?: {
    query: string;
    limit: number;
    page: number;
    offset: number;
    size: number;
    loadMore: boolean;
  };
}

interface SearchResultsResponse {
  channels: PaginatedSearchData | null;
  messages: PaginatedSearchData | null;
}

const shouldLoadMore = ({ page, limit, size }: { page: number; limit: number; size: number }): boolean => {
  if (page === 0 && size < limit) {
    return false;
  }

  if (!size || size === 0) {
    return false;
  }
  return size >= limit;
};

const removeDeleted = (results: SearchMessageResult[]): SearchMessageResult[] => {
  return results?.filter((result) => result?.deletedAt === null || !result?.deletedAt);
};

const removeDuplicatesByKey = ({
  key,
  array,
}: { key: string; array: SearchMessageResult[] | SearchFeedsResult[] | [] }) =>
  array.filter((value, index, self) => index === self.findIndex((t) => t[key] === value[key] && t[key] === value[key]));

export const mutateSearchResponse = ({
  response,
  query,
  limit,
  page,
}: {
  response: any;
  query: string;
  limit: number;
  page: number;
}): PaginatedSearchData => {
  const nextOffset = (page + 1) * limit;
  const responseSize = response?.size ?? 0;
  const loadMore = shouldLoadMore({ page, limit, size: responseSize });
  const filteredResults = removeDeleted(response?.results);

  return {
    results: filteredResults,
    meta: {
      query,
      limit,
      page,
      offset: nextOffset,
      size: responseSize,
      loadMore,
    },
  };
};

export const paginateData = ({ currentData, newData }): PaginatedSearchData => {
  return {
    ...newData,
    results: [...currentData.results, ...removeDeleted(newData.results)],
  };
};

export const getFormattedTime = (dateString: string) => {
  const date = new Date(dateString);
  return dateString ? format(date, "MM/dd/yy hh:mm aaa") : "";
};

export const prettyDate = (date: string) =>
  new Date(date)
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    })
    .toString()
    .replace("at", " ");
