import { AccountEvent, AccountWithWsMembershipAvailability } from "@/db/types";
import { FullItem } from "@/models/FeedContextProvider";
import {
  AccountEventWithExtra,
  CompletedEvent,
  DeviceMutedEvent,
  DriverHeardMessageEvent,
  DriverSawMessageEvent,
  DriverStartedListeningEvent,
  EventTypes,
  GroupsOfEvents,
  PausedEvent,
  PlayedUnknownEvent,
  SeenEvent,
  StartedEvent,
} from "@/transformers/transformedEventTypes";

export const filterOutItemOwner = (events: AccountEvent[], ownerAccountId: string) =>
  events?.filter((e) => ownerAccountId !== e.accountId);

export const filterOutDuplicatesByPattern = (arr: AccountEvent[], pattern: string[]): AccountEvent[] | [] => {
  const seen = [];
  return arr?.filter((item) => {
    const identifier = pattern?.map((pKey) => `${item[pKey]}`).join("-");
    if (seen?.includes(identifier)) {
      return false;
    }
    seen.push(identifier);
    return true;
  });
};

/**
 * Sort by newest event and then filter out duplicates
 * @param events
 */
export const removeOlderEvents = (events: AccountEvent[]) => {
  const sortedEvents = events?.sort((a, b) => {
    const aCreatedAt = new Date(a.createdAt).getTime();
    const bCreatedAt = new Date(b.createdAt).getTime();
    return bCreatedAt - aCreatedAt;
  });
  return filterOutDuplicatesByPattern(sortedEvents, ["name", "accountId"]);
};

export const fetchEventType = ({
  event,
  aliasChannelOwnerId,
}: { event: AccountEvent; aliasChannelOwnerId: string | null }): string => {
  const driverEvent = event.accountId === aliasChannelOwnerId;
  const ListenEvent = event?.name?.toLowerCase()?.includes("listen");
  const deviceMuted = driverEvent && (event.deviceVolume === null || event.deviceVolume === 0);
  const unknown = event.memberAvailability === "unknown";
  const present = event.memberAvailability === "present";
  const playedWhileDriving =
    !deviceMuted && driverEvent && present && ListenEvent && !deviceMuted && event.name === StartedEvent;
  const playedUnknown = driverEvent && unknown && event.name === CompletedEvent && !deviceMuted;
  const completed = driverEvent ? present && event.name === CompletedEvent : event.name === CompletedEvent;
  const started = event.name === StartedEvent;
  const read = event.name === SeenEvent;
  const paused = !driverEvent && event.name === PausedEvent;

  if (deviceMuted) return DeviceMutedEvent;
  if (playedUnknown) return PlayedUnknownEvent;
  if (playedWhileDriving) return DriverHeardMessageEvent;
  if (completed) return driverEvent ? DriverHeardMessageEvent : CompletedEvent;
  if (started) return driverEvent ? DriverStartedListeningEvent : StartedEvent;
  if (read) return driverEvent ? DriverSawMessageEvent : SeenEvent;
  if (paused) return PausedEvent;
  return "";
};

/**
 * Transform Events -> One single source of truth
 * @param processing
 * @param item
 * @param accountMap
 * @param aliasChannelOwnerId
 */
export const transformEvents = ({
  processing,
  item,
  accountMap,
  aliasChannelOwnerId,
}: {
  processing: boolean;
  item: FullItem;
  accountMap: Map<string, AccountWithWsMembershipAvailability>;
  aliasChannelOwnerId: string | null;
}): { allDriverEvents: boolean; count: number; groups: GroupsOfEvents } => {
  // check to see if the item has call records -> If so we don't want to show the events
  const hasCallRecords = item?.callRecords?.length > 0;

  // if no events or processing or has call records return empty
  if (!item?.events || item?.events?.length === 0 || processing || hasCallRecords) {
    return {
      groups: [],
      count: 0,
      allDriverEvents: false,
    };
  }

  // filter out the item owner from the events
  const filteredEvents = filterOutItemOwner(item.events, item.accountId);

  // remove duplicates by sorting first and then only allowing 1 entry per user per event
  const removeOldDuplicateEvents = removeOlderEvents(filteredEvents);

  // map the events to include the username, driverEvent and the event type
  const mappedEvents = removeOldDuplicateEvents?.map((event: AccountEvent) => {
    const eventAccount = accountMap.get(event.accountId);
    const driverEvent = event.accountId === aliasChannelOwnerId;
    const eventType = fetchEventType({ event, aliasChannelOwnerId });
    return {
      ...event,
      eventType,
      driverEvent,
      username: eventAccount?.name,
    };
  });

  // filter out all events if the aliasChannelOwnerId is present and the event is not a driver event
  const events =
    aliasChannelOwnerId && item.accountId !== aliasChannelOwnerId
      ? mappedEvents?.filter((e) => e.driverEvent)
      : mappedEvents;

  // check if all events are driver events
  const allDriverEvents: boolean = events?.every((e) => e.driverEvent);

  // group all events into event blocks by eventType
  const groups = events.reduce(
    (entryMap, e) => entryMap.set(e["eventType"], [...(entryMap.get(e["eventType"]) || []), e]),
    new Map(),
  );

  const mappedData = EventTypes?.map((event) => {
    const events: AccountEventWithExtra[] = groups.get(event);
    const filtered = filterOutDuplicatesByPattern(events, ["eventType", "accountId"]);
    return {
      key: event,
      events: filtered,
    };
  }).filter((item) => item?.events?.length) as GroupsOfEvents;

  // count the total unique user events
  const countTotalUniqueUserEvents: number = filterOutDuplicatesByPattern(
    mappedData?.map((item) => item.events).flat(),
    ["accountId"],
  ).length;

  const foundPlayedUnknownEvent: number = mappedData?.findIndex((item) => item.key === PlayedUnknownEvent);
  const foundDriverHeardEvent: number = mappedData?.findIndex((item) => item.key === DriverHeardMessageEvent);

  // remove double events if one group has both PlayedUnknownEvent and DriverHeardMessageEvent
  if (foundPlayedUnknownEvent >= 0 && foundDriverHeardEvent >= 0) {
    delete mappedData[foundPlayedUnknownEvent];
  }

  return {
    allDriverEvents,
    count: countTotalUniqueUserEvents,
    groups: mappedData,
  };
};
